import React, { memo, useEffect, useCallback, useState } from 'react';
import { Modal, Form } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { createDepositRequest, clearUserServerResponse, getUserData } from '../../../../../redux/ducks/user';
import { makeSimpleData } from '../../../../../heplers/dataCoverter';

import DepositForm from './DepositForm/DepositForm';

const initSliderValues = { first: 0, second: 0 };

const DepositModal = ({ visible, onCancel }) => {
  const [form] = Form.useForm();
  const [isLoading, changeIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [depositType, changeDepositType] = useState({});
  const [sliderValues, setSliderValues] = useState(initSliderValues);
  const serverResponse = useSelector((state) => state.user.serverResponse);
  const requisites = useSelector((state) => state.user.credit.requisites);

  const dispatch = useDispatch();

  const getRequsitesValues = (values) => {
    let a = [];
    requisites.Реквизит && requisites.Реквизит.map((el) => {
      let obj = el;
      obj.Значение =
        el.ВнутреннийТип === 'Дата рождения'
          ? makeSimpleData(values['Дата рождения'])
          : values[el.Наименование] === undefined
          ? undefined
          : values[el.Наименование];
      if(el.ВнутреннийТип === 'Произвольный реквизит (булево)' && el.Обязателен) {
        obj.Значение = false;
      };
      if(obj.Значение !== undefined) {
        delete obj.Представление;
        delete obj.НомерСтроки;
        a.push(obj);
      }
      return obj;
    });
    return a;
  };

  const onCreate = (values) => {
    let obj = {
      ...values,
      ЕдИзмСрока: depositType.ЕдИзмСрока,
      СписокЗначенийРеквизитов: getRequsitesValues(values),
      ВидСбережения: depositType,
    };
    if(!obj.СписокЗначенийРеквизитов.length){
      delete obj.СписокЗначенийРеквизитов;
    };
    // console.log(obj)
    changeIsLoading(true);
    dispatch(createDepositRequest(obj));
  };

  const clearModal = useCallback(() => {
    setSliderValues(initSliderValues);
    setDisabled(true);
    changeDepositType({});
    form.resetFields();
  }, [form]);

  const closeConfirm = () => {
    let values = form.getFieldsValue();
    // console.log(JSON.stringify(values));
    if (JSON.stringify(values) === '{}') {
      onCancel();
    } else {
      Modal.confirm({
        // title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content:
          'Вы действительно хотите прервать процесс подачи заявки на сбережение? Введенные данные не сохранятся.',
        okText: 'Да',
        cancelText: 'Нет',
        centered: true,
        onOk: () => {
          onCancel();
          setTimeout(() => {
            clearModal();
          }, 100);
        },
      });
    }
  };

  const loanErrorConfirm = useCallback((err) => {
    Modal.error({
      title: 'Ошибка!',
      content: err,
      onOk: () => {},
    });
  }, []);

  const loanSuccessConfirm = useCallback(
    (msg) => {
      Modal.success({
        title: 'Готово!',
        content: msg ? msg : 'Ваша заявка успешно отправленна! Ожидайте звонка специалиста.',
        centered: true,
        onOk: () => {
          onCancel();
          dispatch(getUserData());
          setTimeout(() => {
            clearModal();
          }, 100);
        },
      });
    },
    [onCancel, clearModal, dispatch],
  );

  useEffect(() => {
    if (serverResponse && serverResponse.status === 200 && isLoading) {
      changeIsLoading(false);
      if (serverResponse.data.return.КодОшибки && serverResponse.data.return.КодОшибки === '0') {
        // console.log('ffffffff', serverResponse)
        loanSuccessConfirm(serverResponse.data.return.ОписаниеОшибки);
      } else {
        loanErrorConfirm(
          serverResponse.data.return ? serverResponse.data.return.ОписаниеОшибки : '',
        );
      }
      dispatch(clearUserServerResponse());
    }
    if (serverResponse && serverResponse.status === 'error' && isLoading) {
      changeIsLoading(false);
      dispatch(clearUserServerResponse());
    };
    // if (serverResponse && serverResponse.status !== 200 && isLoading) {
    //   changeIsLoading(false);
    //   dispatch(clearUserServerResponse());
    //   notification.error({
    //     message: 'Ошибка',
    //     description: 'Проблемы с сервером',
    //   });
    // }
  }, [serverResponse, loanSuccessConfirm, loanErrorConfirm, isLoading, dispatch]);

  return (
    <Modal
      open={visible}
      title='Заявка на сбережение'
      okText='Отправить заявку на рассмотрение'
      cancelText='Выйти'
      onCancel={closeConfirm}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
      okButtonProps={{ disabled: disabled }}
      className='change-password-modal'
      centered
      confirmLoading={isLoading}
    >
      <DepositForm
        form={form}
        setDisabled={setDisabled}
        sliderValues={sliderValues}
        setSliderValues={setSliderValues}
        isLoading={isLoading}
        depositType={depositType}
        changeDepositType={changeDepositType}
      />
    </Modal>
  );
};

export default memo(DepositModal);
