import axios from 'axios';
import { changeGlobalError } from './globalError';
import getDefaultHeader from '../../heplers/getDefaultHeader';

const BASE_API_PATH = import.meta.env.VITE_REACT_APP_API_URL;

// Action types
const ADD_SMS_SERVER_RESPONSE = 'SMS/ Add server response';
const CLEAR_SMS_SERVER_RESPONSE = 'SMS/ Clear server response';

// Reducer
const inititalState = {
  serverResponse: {},
};

// Reducer
const sms = (state = inititalState, action) => {
  switch (action.type) {
    case ADD_SMS_SERVER_RESPONSE:
      return {
        ...state,
        serverResponse: action.payload,
      };
    case CLEAR_SMS_SERVER_RESPONSE:
      return {
        ...state,
        serverResponse: {},
      };
    default:
      return state;
  }
};

export const clearSmsServerResponse = () => {
  return {
    type: CLEAR_SMS_SERVER_RESPONSE,
  };
};

// Отправить мне смс сообщение
// phone - номер телефона,
// skipSmsTimeout - используется только в лендинге ESIARegisterPage только когда нажали кнопку отправить заявку
// type - также используем в лендинге, либо это регистрация юзера нового, либо запрос заявки на займ
export const sendSms =
  ({ phone = null, skipSmsTimeout = false, type = null }) =>
  (dispatch, getState) => {
    let obj = {};
    const state = getState();

    if (phone) {
      obj.phone = phone;
    } else {
      //const state = getState();
      const user_phone =
        state.user.data.ИнформацияОКлиенте && state.user.data.ИнформацияОКлиенте.ФизЛицо
          ? state.user.data.ИнформацияОКлиенте.ФизЛицо.МобильныйТелефон
          : null;
      // это перестраховка если мы залогинились через ЕСИА,
      // то в локале у нас номера нет и надо его из инфы о клиенте брать
      if (user_phone) {
        let b = user_phone.replace(/[^\d;]/g, '').substr(-10, 10);
        obj.phone = b;
      } else {
        obj.phone = JSON.parse(localStorage.getItem('ph'));
      }
    }

    if (skipSmsTimeout) {
      obj.skip = true;
    }

    if (type) {
      obj.type = type;
    }

    if (state.user.data.ИнформацияОКлиенте && state.user.data.ИнформацияОКлиенте.UIDКлиента) {
      //console.log(state);
      obj.uidclient = localStorage.getItem('uid_cli'); //state.user.data.ИнформацияОКлиенте.UIDКлиента;
      obj.uidsess = localStorage.getItem('uid_ses');
    }

    axios
      .post(`${BASE_API_PATH}/user/send_sms`, obj, getDefaultHeader())
      .then((res) => {
        dispatch({
          type: ADD_SMS_SERVER_RESPONSE,
          payload: res,
        });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
          dispatch({
            type: ADD_SMS_SERVER_RESPONSE,
            payload: err,
          });
          // dispatch(changeGlobalError('server_error'));
        } else {
          dispatch(changeGlobalError('no_internet'));
        }
      });
  };

//  Валидация смс сообщения
export const checkSms = (vals) => (dispatch, getState) => {
  let obj = {
    ...vals,
  };

  if (!obj.phone) {
    const state = getState();
    const user_phone =
      state.user.data.ИнформацияОКлиенте && state.user.data.ИнформацияОКлиенте.ФизЛицо
        ? state.user.data.ИнформацияОКлиенте.ФизЛицо.МобильныйТелефон
        : null;

    // это перестраховка если мы залогинились через ЕСИА,
    // то в локале у нас номера нет и надо его из инфы о клиенте брать

    if (user_phone) {
      let b = user_phone.replace(/[^\d;]/g, '').substr(-10, 10);
      obj.phone = b;
    } else {
      obj.phone = JSON.parse(localStorage.getItem('ph'));
    }
  }

  axios
    .post(`${BASE_API_PATH}/user/check_sms`, obj, getDefaultHeader())
    .then((res) => {
      dispatch({
        type: ADD_SMS_SERVER_RESPONSE,
        payload: { ...res, ...vals },
      });
    })
    .catch((err) => {
      if (err.response) {
        console.log(err.response);
        dispatch(changeGlobalError('server_error'));
      } else {
        dispatch(changeGlobalError('no_internet'));
      }
    });
};

export default sms;

