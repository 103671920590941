import React, { useState } from 'react';
import { Form, Input, Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './styles.less';

const CustomToolbar = () => (
  <div id='toolbar'>
    <button className='ql-bold' />
    <button className='ql-italic' />
    <button className='ql-link' />
  </div>
);

// const { TextArea } = Input;

const MsgForm = ({ form, isLoading, text, setText }) => {
  const [fileList, setFileList] = useState([]);

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      let a = [...fileList, file];
      setFileList(a);
      return false;
    },
    fileList,
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleUploadFile = (e) => {};

  return (
    <Form form={form} layout='vertical' name='msg_modal' size={'middle'}>
      <Form.Item
        name='Тема'
        label='Тема сообщения'
        rules={[
          {
            required: true,
            message: `Заполните тему`,
          },
        ]}
      >
        <Input
          type='string'
          autoComplete='off'
          autoCorrect='off'
          spellCheck='false'
          allowClear
          readOnly={isLoading}
        />
      </Form.Item>
      <Form.Item
        name='ТекстСообщения'
        label='Текст сообщения'
        value={text}
        rules={[
          {
            required: true,
            message: `Заполните текст сообщения`,
          },
        ]}
      >
        {/* <TextArea rows={4} readOnly={isLoading} allowClear/> */}
        <div className='text-editor'>
          {/* <CustomToolbar /> */}
          {/* <ReactQuill
            value={text}
            onChange={(t) => setText(t)}
            modules={modules}
            formats={formats}
            style={{ minHeight: '150px' }}
            readOnly={isLoading}
          /> */}
        </div>
        <ReactQuill
          value={text}
          onChange={(t) => setText(t)}
          formats={formats}
          style={{ minHeight: '150px' }}
          readOnly={isLoading}
        />
      </Form.Item>

      {/*
			<label htmlFor="uploadFile" className="ant-btn ant-btn-primary">Прикрепить файл</label>
			<input
				type="file"
				onChange={handleUploadFile}
				name="file" id="uploadFile"
				style={{ display: 'none' }}
				multiple={true}
				accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*, .doc, .docx, .odt, .pdf, .xls, .xlsx, .ods, .numbers"
			/>
			*/}

      <label style={{ display: 'block' }} htmlFor='msg_modal_upload'>
        <Form.Item
          name='upload'
          // label="Upload"
          valuePropName='fileList'
          getValueFromEvent={normFile}
          // extra="longggggggggggggggggggggggggggqgggggggg"
        >
          <Upload
            name='logo'
            listType='text'
            multiple={true}
            accept='image/*, application/pdf, .doc, .docx, .xml, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            //accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*, .doc, .docx, .odt, .pdf, .xls, .xlsx, .ods, .numbers"
            {...props}
          >
            <Button disabled={isLoading}>
              <UploadOutlined /> Прикрепить файлы
            </Button>
          </Upload>
        </Form.Item>
      </label>
    </Form>
  );
};

export default MsgForm;

// const modules = {
//   toolbar: {
//     container: '#editor',
//     handlers: {
//       // insertStar: insertStar
//     },
//   },
//   clipboard: {
//     matchVisual: false,
//   },
// };

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
];
