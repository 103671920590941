import React, { memo } from 'react';
import { Result, Button } from 'antd';
import useHistoryNavigation from '../../hooks/useHistoryNavigation';

const ErrorPage = () => {
  const { goToMain } = useHistoryNavigation();
  return (
    <div className='error-page'>
      <Result
        // status="404"
        title="404"
        subTitle="Такой страницы не существует"
        extra={<Button type="primary" onClick={goToMain}>На главную</Button>}
        />
    </div>
  );
};

export default memo(ErrorPage);