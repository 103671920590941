const downloadFileFromUrl = (url, filename) => {
  // для мобильного приложения, в локал флажок кладется на стороне приложения.
  let isMobile = localStorage.getItem('isMobile');
  if (isMobile) {
    window.flutter_inappwebview.callHandler('sendFile', url).then(function(result) {});
  } else {
    let xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function(e) {
      if (this.status === 200) {
        const blob = this.response;
        const a = document.createElement("a");
        document.body.appendChild(a);
        const blobUrl = window.URL.createObjectURL(blob);
        a.href = blobUrl;
        a.download = filename;
        a.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(blobUrl);
          document.body.removeChild(a);
        }, 0);
      }
    };
    xhr.send();
  }
}

export default downloadFileFromUrl