import React, { memo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Layout, Row, Col } from 'antd';
import logo from '../../assets/images/logo.png';
import useWindowSize from '../../hooks/useWindowSize';
import './ESIARegister.less';
import Block1 from './components/Block1/Block1';
import Block2 from './components/Block2/Block2';
import Block3 from './components/Block3/Block3';
import Loader from '../../common/components/Loader/Loader';

import { getESIALoanTerms } from '../../redux/ducks/user';
import useHistoryNavigation from '../../hooks/useHistoryNavigation';

const { Header, Content } = Layout;
const BASE_API_PATH = import.meta.env.VITE_REACT_APP_API_URL;

console.log('BASE_API_PATH ---->', BASE_API_PATH);

const ESIARegisterPage = () => {
  const config = useSelector((state) => state.config);

  const [mainHeight, setMainHeight] = useState(0);
  const user = useSelector((state) => state.user);

  const [activeBlock, setActiveBlock] = useState(1);
  const { goToLogin } = useHistoryNavigation();
  const [colSizes, setColSizes] = useState({ span: 6, offset: 14 });
  const { height, width } = useWindowSize();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const fileIsLoading = useSelector((state) => state.messages.fileIsLoading);

  // если на эту страницу попали без кода есиа - уходим на логин
  useEffect(() => {
    if (!user.esia.is) {
      goToLogin();
    } else {
      dispatch(getESIALoanTerms());
    }
  }, [goToLogin, user.esia.is, dispatch]);

  // Высчитываем высоту основного блока
  useEffect(() => {
    let a = document.querySelector('.main-header');
    let b = a.getBoundingClientRect();
    setMainHeight(height - b.height);
  }, [height]);
  useEffect(() => {
    if (width > 1180) {
      if (colSizes.span !== 7) {
        setColSizes({ span: 7 });
      }
    } else if (width > 950 && width <= 1180) {
      if (colSizes.span !== 8) {
        setColSizes({ span: 8 });
      }
    } else if (width > 768 && width <= 950) {
      if (colSizes.span !== 9) {
        setColSizes({ span: 9 });
      }
    } else {
      if (colSizes.span !== 24) {
        setColSizes({ span: 24 });
      }
    }
  }, [width, colSizes.span]);

  return (
    <>
      <Loader isLoading={fileIsLoading} />
      <Loader isLoading={isLoading} />
      <Layout className='main-page esia-page'>
        <Header className='main-header'>
          <nav>
            <Link to='/login'>
              <img src={config.logo ? `${BASE_API_PATH}${config.logo}` : logo} alt='logo' />
              {config.name ? config.name : 'Микрозаймы'}
            </Link>
            {/* Регистрация */}
          </nav>
        </Header>
        <Layout style={{ minHeight: `${mainHeight}px` }}>
          <Content className='main-content' style={{ minHeight: `${mainHeight}px` }}>
            <Row style={{ minHeight: `${mainHeight}px` }}>
              <Col span={colSizes.span} className='esia-primary'>
                <Row>
                  <Block1
                    active={{
                      activeBlock: activeBlock,
                      setActiveBlock: setActiveBlock,
                    }}
                    loading={{
                      isLoading: isLoading,
                      setIsLoading: setIsLoading,
                    }}
                  />
                </Row>
                <Row>
                  <Block2
                    active={{
                      activeBlock: activeBlock,
                      setActiveBlock: setActiveBlock,
                    }}
                  />
                </Row>
                {width <= 768 && (
                  <Row>
                    <Block3
                      active={{
                        activeBlock: activeBlock,
                        setActiveBlock: setActiveBlock,
                      }}
                    />
                  </Row>
                )}
              </Col>
              {width > 768 && (
                <Col span={24 - colSizes.span}>
                  <Block3
                    active={{
                      activeBlock: activeBlock,
                      setActiveBlock: setActiveBlock,
                    }}
                  />
                </Col>
              )}
            </Row>
          </Content>
          {/* <Footer>{config.footer_text ? config.footer_text : 'Личный кабинет'} Версия 1.1.0</Footer> */}
        </Layout>
      </Layout>
    </>
  );
};

export default memo(ESIARegisterPage);

