import React, { memo, useState, useEffect } from 'react';

import { Form, Input, Button, Alert, notification } from 'antd';
import { PhoneOutlined, LockOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import { signIn, clearUserServerResponse } from '../../../../redux/ducks/user';
import addUserToLocal from '../../../../heplers/addUserToLocal';
import useHistoryNavigation from '../../../../hooks/useHistoryNavigation';

const LoginForm = ({ visible, esia_url }) => {
  const [isPhone, setIsPhone] = useState(false);
  const [isLoading, changeIsLoading] = useState(false);
  const [error, changeError] = useState('');

  const serverResponse = useSelector((state) => state.user.serverResponse);

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (visible.register || visible.restore) {
      form.resetFields();
      if (error) {
        changeError('');
      }
    }
  }, [visible, form, error]);

  const { goToMain } = useHistoryNavigation();

  useEffect(() => {
    if (serverResponse && serverResponse.status === 200 && isLoading) {
      // console.log(serverResponse);
      changeIsLoading(false);
      if (serverResponse.data.return.КодОшибки === '0') {
        Promise.resolve()
          .then(() => {
            addUserToLocal({ ...serverResponse.data.return, phone: serverResponse.phone });
          })
          .then(() => {
            goToMain();
          });
      } else {
        changeError(serverResponse.data.return.ОписаниеОшибки);
      }
      dispatch(clearUserServerResponse());
    }
    if (serverResponse && serverResponse.status === 401 && isLoading) {
      changeIsLoading(false);
      dispatch(clearUserServerResponse());
      notification.error({
        message: 'Ошибка',
        description: 'Проблемы с сервером',
      });
    }
    if (serverResponse && serverResponse.status === 'error' && isLoading) {
      changeIsLoading(false);
      dispatch(clearUserServerResponse());
    }
  }, [serverResponse, dispatch, goToMain, isLoading]);

  const onFinish = (values) => {
    if (error) {
      changeError(false);
    }
    changeIsLoading(true);

    dispatch(signIn(values));
  };

  return (
    <div className='login-form-wrapper'>
      <Form
        form={form}
        name='login-form'
        initialValues={{ remember: true }}
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        className='login-form'
      >
        <Form.Item
          name='phone'
          rules={[
            { required: true, message: 'Введите номер телефона!' },
            { max: 10, message: 'Номер слишком длинный' },
            { min: 10, message: 'Номер слишком короткий' },
          ]}
          hasFeedback
        >
          <Input
            // autoComplete='off'
            // autoCorrect='off'
            // spellCheck='false'
            type='number'
            size='large'
            placeholder='Номер телефона'
            prefix={!isPhone ? <PhoneOutlined className='site-form-item-icon' /> : '+7'}
            onFocus={(e) => setIsPhone(true)}
            onBlur={(e) => (!e.target.value ? setIsPhone(false) : undefined)}
            readOnly={isLoading}
            // allowClear
          />
        </Form.Item>

        <Form.Item
          name='password'
          rules={[{ required: true, message: 'Введите пароль!' }]}
          hasFeedback
        >
          <Input.Password
            placeholder='Пароль'
            size='large'
            prefix={<LockOutlined className='site-form-item-icon' />}
            readOnly={isLoading}
            // allowClear
          />
        </Form.Item>

        <Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            className='login-form-button'
            loading={isLoading}
          >
            Отправить
          </Button>
        </Form.Item>

        {esia_url &&
          <Button type='primary'>
            <a href={esia_url} rel='noopener noreferrer'>
              Войти через госуслуги (ЕСИА)
            </a>
          </Button>
        }
      </Form>

      {error && (
        <Alert
          message={error}
          type='error'
          closable
          afterClose={() => {
            changeError('');
          }}
        />
      )}
    </div>
  );
};

export default memo(LoginForm);
