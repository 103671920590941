import React, { memo, useEffect, useCallback, useState } from 'react';
import { Modal, Form } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { ExclamationCircleOutlined, SendOutlined, CloseOutlined } from '@ant-design/icons';
import { getMessages, createMessage, clearMessagesServerResponse } from '../../../../../redux/ducks/messages';

import MsgForm from './MsgForm/MsgForm';

//test new git rep 2
const MsgModal = ({ visible, onCancel, initMsg, clearInitMsg, setNewMsg }) => {

  const [isLoading, changeIsLoading] = useState(false);
  const [text, setText] = useState('');

  const [form] = Form.useForm();
  const serverResponse = useSelector((state) => state.messages.serverResponse);

  const dispatch = useDispatch();

  useEffect(() => {
    if(text){
      form.setFieldsValue({'ТекстСообщения': text})
    } else {
      if(form) {
        setText('')
      }
    }
  }, [text, form]);

  const onCreate = (values) => {
    if (values.upload && values.upload.length) {
      let obj = {
        Тема: values.Тема,
        ТекстСообщения:  values.ТекстСообщения,
      };

      values.upload.map((el, ind) => {
        return obj[`file-${ind}`] = el.originFileObj
      });

      if (initMsg.UIDСообщения) {
        obj.UIDОснование = initMsg.UIDСообщения;
        obj.ТипОснование = initMsg.init ? 'СообщениеЛичногоКабинетаИсходящее' : 'СообщениеЛичногоКабинетаВходящее'
      };

      // console.log(obj);
      if (!values.upload.includes(null)) {
        changeIsLoading(true);
        dispatch(createMessage(obj));
      };

    } else {
      let obj = {
        Тема: values.Тема,
        ТекстСообщения: values.ТекстСообщения,
      };
      if (initMsg.UIDСообщения) {
        obj.UIDОснование = initMsg.UIDСообщения;
        obj.ТипОснование = initMsg.init ? 'СообщениеЛичногоКабинетаИсходящее' : 'СообщениеЛичногоКабинетаВходящее'
      };
        changeIsLoading(true);
        dispatch(createMessage(obj));
    };
  };

  const clearModal = useCallback(() => {
    form.resetFields();
    setText('');
  }, [form]);

  const closeConfirm = () => {
    let values = form.getFieldsValue();

    if (JSON.stringify(values) === '{}') {
      onCancel();
    } else {
      Modal.confirm({
        // title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content:
          'Вы действительно хотите прервать процесс отправки сообщения? Введенные данные не сохранятся.',
        okText: 'Да',
        cancelText: 'Нет',
        centered: true,
        onOk: () => {
          onCancel();
          setTimeout(() => {
            clearModal();
          }, 200);
        },
      });
    }
  };

  const msgErrorConfirm = useCallback((err) => {
    Modal.error({
      title: 'Ошибка!',
      content: err,
      onOk: () => {},
    });
  }, []);

  const msgSuccessConfirm = useCallback(() => {
    Modal.success({
      title: 'Готово!',
      content: 'Сообщение отправлено',
      centered: true,
      onOk: () => {
        onCancel();
        setNewMsg(true);
        // console.log('get msg from here')
        dispatch(getMessages());
        setTimeout(() => {
          clearModal();
        }, 200);
      },
    });
  }, [ onCancel, clearModal, dispatch, setNewMsg ]);

  useEffect(() => {
    if (serverResponse && serverResponse.status === 200 && isLoading) {
      changeIsLoading(false);
      if (serverResponse.data.return.КодОшибки === '0') {
        msgSuccessConfirm(serverResponse.data.return.ОписаниеОшибки);
      } else {
        msgErrorConfirm(serverResponse.data.return.ОписаниеОшибки);
      }
      dispatch(clearMessagesServerResponse());
    };
    if (serverResponse && serverResponse.status === 'error' && isLoading) {
      changeIsLoading(false);
      dispatch(clearMessagesServerResponse());
    };
    if(serverResponse && serverResponse.status === 500 && isLoading) {
      changeIsLoading(false);
      dispatch(clearMessagesServerResponse());
    }
  }, [serverResponse, msgSuccessConfirm, msgErrorConfirm, isLoading, dispatch]);

  return (
    <Modal
      open={visible}
      title='Сообщение'
      okText='Отправить сообщение'
      cancelText='Выйти'
      onCancel={closeConfirm}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
      okButtonProps={{ icon: <SendOutlined /> }}
      className='change-password-modal'
      centered
      confirmLoading={isLoading}
    >
      {initMsg.UIDСообщения &&
        <div className='reply-msg'>
        <CloseOutlined className='reply-msg-icon' onClick={clearInitMsg}/>
          <p>{initMsg.Автор ? initMsg.Автор : 'Вы'}</p>
          <span>{ReactHtmlParser(initMsg.ТекстСообщения)}</span>
        </div>
      }
      <MsgForm
        form={form}
        isLoading={isLoading}
        text={text}
        setText={setText}
      />
    </Modal>
  );
};

export default memo(MsgModal);
