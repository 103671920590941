import React, { memo, useState, useEffect, useCallback } from 'react';
import { Form, Input, Button, message } from 'antd';
import { sendSms, checkSms, clearSmsServerResponse } from '../../../../redux/ducks/sms';
import { useDispatch, useSelector } from 'react-redux';
import { makeSimpleTime } from '../../../../heplers/dataCoverter';

const initLoaders = { again: false, init: false };

const SMSFormRegister = ({ form, isLoading, visible, type }) => {
  const [loaders, setLoaders] = useState(initLoaders);
  const [err, setErr] = useState('');

  const serverResponse = useSelector((state) => state.sms.serverResponse);

  const dispatch = useDispatch();

  useEffect(() => {
    if (visible) {
      setLoaders((l) => {
        return { ...l, init: true };
      });
      dispatch(sendSms({ type: type }));
    };
  }, [visible, dispatch, type]);

  const clearForm = useCallback(() => {
    form.resetFields();
    setLoaders(initLoaders);
    dispatch(clearSmsServerResponse());
  }, [form, dispatch]);

  useEffect(() => {
    if (!visible) {
      clearForm();
    }
  }, [visible, clearForm]);

  useEffect(() => {
    if (visible) {
      if (serverResponse && serverResponse.status === 200 && (loaders.init || loaders.again)) {
        setLoaders(initLoaders);
        if (serverResponse.data.result === 'success') {
          message.info('Сообщение было отправлено на Ваш мобильный телефон');
        } else if (serverResponse.data.result === 'error') {
          const d1 = new Date(serverResponse.data.next_window_at * 1000);
          d1.toString();
          // console.log(d1)
          setErr(
            `Код уже отправлен Вам на мобильный телефон, повторная отправка возможна в ${makeSimpleTime(
              d1,
            )}`,
          );
        } else {
          message.error('Ошибка сервера или смс провайдера');
        }
        setLoaders(initLoaders);
        dispatch(clearSmsServerResponse());
      };
    };
  }, [serverResponse, dispatch, loaders, visible]);

  const sendSmsAgain = () => {
    dispatch(sendSms({ type: type }));
    setErr('');
    setLoaders({ ...loaders, again: true });
  };

  const onFinish = (values) => {
    dispatch(checkSms(values));
    setLoaders({ ...loaders, send: true });
    if (err) {
      setErr('');
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Form
        form={form}
        name='sms-form'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout='vertical'
      >
        <Form.Item
          label='Введите код из смс'
          name='code'
          rules={[
            {
              required: true,
              message: 'Введите код из смс',
            },
          ]}
        >
          <Input
            autoComplete='off'
            autoCorrect='off'
            spellCheck='false'
            allowClear
            readOnly={isLoading}
          />
        </Form.Item>
        <div>
          <div className='sms-btns-wrapper'>
            <Button
              loading={loaders.again}
              onClick={sendSmsAgain}
              disabled={isLoading}
              className='sms-btn-again'
            >
              Отправить код еще раз
            </Button>
          </div>
          {err && (
            <p className='sms-error'>{err}</p>
          )}
        </div>
      </Form>
    </>
  );
};

export default memo(SMSFormRegister);
