import React, { memo } from 'react';
import { Modal, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { loadFile } from '../../../../redux/ducks/messages';
import { PaperClipOutlined } from '@ant-design/icons';

const LoanTermsModal = ({ visible, onCancel, title }) => {

  const specificMessage = useSelector((state) => state.user.credit.specificMessage);
  const dispatch = useDispatch()

  const getFiles = (files, flag) => {
    let arr = [];
    if (Array.isArray(files)) {
      arr = [...files];
    } else {
      let a = [];
      a.push(files);
      arr = [...arr, ...a];
    }

    return arr.map((el) => (
      <p key={el.UIDФайла} onClick={() => dispatch(loadFile(el.UIDФайла, flag))}>
        <PaperClipOutlined /> {el.Наименование}.{el.РасширениеФайла}
      </p>
      // <Button type='link' key={el.UIDФайла} onClick={() => dispatch(loadFile(el.UIDФайла, flag))}>
      //   <PaperClipOutlined /> {el.Наименование}.{el.РасширениеФайла}
      // </Button>
    ));
  };

  return (
    <Modal
      open={visible}
      title={title}
      onCancel={() => {
        onCancel()
      }}
      centered
      className='info-loan-modal'
      style={{maxWidth: '500px'}}
    >
      <div
        className='file-wrapper'
      >
        {specificMessage.ВложенныеФайлы ? (
          <div className='links-holder'>
            {getFiles(specificMessage.ВложенныеФайлы.Файл, specificMessage.Автор ? false : true)}
          </div>
        ) : (
          <div className='file-loader'>
            <Spin size='large' />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default memo(LoanTermsModal);
