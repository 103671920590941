const mode = import.meta.env.MODE
const BASE_API_URL = import.meta.env.VITE_REACT_APP_LKHOST;

const getDefaultHeader = () => {
  if (mode === 'PROXY') {
    return {
      headers: {
        'Content-type': 'application/json',
        'X-LkHost': BASE_API_URL,
      },
    };
  } else {
    return {
      headers: {
        'Content-type': 'application/json',
      },
    };
  }
};

export default getDefaultHeader;
