import React, { memo, useEffect, useCallback, useState } from 'react';
import { Modal, Button, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import SMSFormAccept from '../../Forms/SMSFormAccept/SMSFormAccept';
import { acceptMessage } from '../../../../redux/ducks/messages';

const initDisable = { is: true, КодСМС: '' };

// Модалка подписи спецсообщения при создании заявки на займ
const LoanAcceptSmsModal = ({ visible, onCancel, UIDСообщения, onSuccessSmsAccept, initClean, skipSmsTimeout, okText }) => {

  const [isLoading, changeIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(initDisable);
  const [smsWork, setSmsWork] = useState(false);
  const [loanDisabled, setLoanDisabled] = useState(true);
  const [isLoadingLoan, setIsLoadingLoan] = useState(false);

  const msgWork = useSelector((state) => state.messages.msgWork);
  const dispatch = useDispatch();

  const smsServerResponse = useSelector((state) => state.sms.serverResponse)

  // Подпись сообщения
  const onCreate = () => {
    let obj = {
      UIDСообщения,
      Подписано: true,
    };
    if (disabled.КодСМС) {
      obj.КодСМС = disabled.КодСМС;
    }
    // console.log(obj);
    changeIsLoading(true);
    dispatch(acceptMessage(obj));
  };

  // Чистим модалку при закрытии или успехе.
  const clearModal = useCallback(() => {
    onCancel();
    setDisabled(initDisable);
    setSmsWork(false);
    setLoanDisabled(true);
    changeIsLoading(false);
    setIsLoadingLoan(false);
  }, [onCancel]);

  // Если модалка закрывается - инициируем чистку форм
  useEffect(() => {
    if(initClean.initClean){
      clearModal();
      initClean.setInitClean();
    };
  }, [initClean, clearModal])

  // Если Сообщение подписано, то форма подписи становится дизейбл,
  // а кнопка отправки заявки на займ активной
  useEffect(() => {
    if (msgWork && msgWork.Подписан && isLoading) {
      // clearModal();
      setLoanDisabled(false)
      changeIsLoading(false);
      setDisabled((d) => { return { ...d, is: true }} );
      message.info('Подпись подтверждена');
      // changeIsLoading(false);
    };
    if (msgWork && msgWork.status === 'error' && isLoading) {
      changeIsLoading(false);
    };
  }, [msgWork, isLoading, clearModal]);

  // Если в модалке были внесены какие-то изменения после открытия,
  // то спрашиваем правда ли хотим закрыть модалку.
  const closeConfirm = () => {
    if (
      ((disabled.is && disabled.КодСМС === '') || !disabled.is) &&
      !smsWork
    ) {
      onCancel();
    } else {
      Modal.confirm({
        // title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content:
          'Вы действительно хотите прервать процесс подписи? Введенные данные не сохранятся.',
        okText: 'Да',
        cancelText: 'Нет',
        centered: true,
        onOk: () => {
          onCancel();
          setTimeout(() => {
            clearModal();
          }, 100);
        },
      });
    }
  };

  useEffect(() => {
    if(isLoading && smsServerResponse.status === 'succes') {
      setDisabled({...disabled, is: false});
    };
  },[disabled, dispatch, isLoading, smsServerResponse]);

  return (
    <Modal
      open={visible}
      title='Подпись'
      okText={okText}
      cancelText='Выйти'
      onCancel={closeConfirm}
      onOk={() => {
        setIsLoadingLoan(true);
        onSuccessSmsAccept();
      }}
      className='change-password-modal'
      centered
      confirmLoading={isLoadingLoan}
      okButtonProps={{ disabled: loanDisabled }}
    >
      <SMSFormAccept
        setDisabled={setDisabled}
        disabled={!loanDisabled ? loanDisabled : disabled}
        setSmsWork={setSmsWork}
        smsWork={smsWork}
        visible={visible}
        initClean={initClean}
        skipSmsTimeout
        type='request'
      />
      <Button
        type='primary'
        disabled={disabled.is}
        loading={isLoading}
        onClick={onCreate}
      >
        Подтвердить подпись
      </Button>
    </Modal>
  );
};

export default memo(LoanAcceptSmsModal);
