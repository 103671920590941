import { Form, Input } from 'antd';
import React, { memo, useEffect, useState } from 'react';

import Name from '../../Inputs/Name';
import Password from '../../Inputs/Password';
import Phone from '../../Inputs/Phone';
import RePassword from '../../Inputs/RePassword';
import PasswordRules from '../../PasswordRules/PasswordRules';

const RegistrationForm = ({ form, isLoading }) => {
  return (
    <>
      <Form form={form} layout='vertical' name='form_in_modal'>
        <Name />
        <Phone />

        <Password isLoading={isLoading} />

        <small className='form-text'>
          <PasswordRules />
        </small>

        <RePassword isLoading={isLoading} />
      </Form>
    </>
  );
};

export default memo(RegistrationForm);

