let isChars = "!@#$%^&*()+=-[]\\';,./{}|\":<>?";

const validatePassword = (pass, rules) => {
  let length = rules.find(el => el.key === 'Длина') ? +(rules.find(el => el.key === 'Длина').length) : 6;
  if (pass.length < length){
    return false
  };
  let arr = rules.filter(el => el.key !== 'Длина');
  pass.split('').map(c => {
    if (!isNaN(+c)) {
      arr = arr.map(el => el.key === 'Цифры' ?  {...el, rule: true} : el)
    } else if (!isChars.includes(c) && c === c.toUpperCase()){
      arr = arr.map(el => el.key === 'Врег' ?  {...el, rule: true} : el)
    } else if (isChars.includes(c)){
      arr = arr.map(el => el.key === 'Спец' ?  {...el, rule: true} : el)
    } else {
    };
    return c;
  })
  return arr.every(el => el.rule);
};

export default validatePassword;