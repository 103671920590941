import makeNumberPretty from './makeNumberPretty';

const getFine = ({ vals, min }) => {
  let p = +vals?.Номинал + +vals?.Комиссия + +vals?.Пени + +vals?.ПениКомиссия; // + (+vals.Проценты)
  let c = +p + +vals?.Проценты;
  if (min) {
    c = c - +min;
  }
  return `${makeNumberPretty(c)}`;
};

export {getFine}