import React, { memo } from 'react';
import { Row, Button, Dropdown, Menu } from 'antd';
import { DownloadOutlined, CaretDownOutlined } from '@ant-design/icons';

const BtnsBlock = ({ setVisible, uid, openPayment, settings, openEarly, openQR, setType }) => {
  return (
    <>
      {settings.ОтображатьКарточку && (
        <Row className='card-row'>
          <div className='card-row-btns'>
            <Button
              type='primary'
              className='card-row-btn'
              onClick={() =>
                setVisible({
                  is: true,
                  title: 'Карточка займа',
                  uid: uid,
                  type: 'ОтчетКарточка',
                })
              }
            >
              Карточка займа
            </Button>

            <Dropdown
              overlay={() => (
                <Menu>
                  <Menu.Item
                    key='1'
                    onClick={() =>
                      setVisible({
                        is: false,
                        title: 'Карточка займа',
                        uid: uid,
                        type: 'ОтчетКарточка',
                      })
                    }
                  >
                    Скачать PDF
                  </Menu.Item>
                </Menu>
              )}
              trigger={['click']}
            >
              <Button type='primary' icon={<DownloadOutlined />} />
            </Dropdown>
          </div>
        </Row>
      )}
      {settings.ОтображатьГрафик && (
        <Row className='card-row'>
          <div className='card-row-btns'>
            <Button
              type='primary'
              className='card-row-btn'
              onClick={() =>
                setVisible({
                  is: true,
                  title: 'График платежей',
                  uid: uid,
                  type: 'ОтчетГрафик',
                })
              }
            >
              График платежей
            </Button>
            <Dropdown
              overlay={() => (
                <Menu>
                  <Menu.Item
                    key='1'
                    onClick={() =>
                      setVisible({
                        is: false,
                        title: 'График платежей',
                        uid: uid,
                        type: 'ОтчетГрафик',
                      })
                    }
                  >
                    Скачать PDF
                  </Menu.Item>
                </Menu>
              )}
              trigger={['click']}
            >
              <Button type='primary' icon={<DownloadOutlined />} />
            </Dropdown>
          </div>
        </Row>
      )}
      {settings.ОтображатьСправку && (
        <Row className='card-row'>
          <div className='card-row-btns'>
            <Button
              type='primary'
              className='card-row-btn'
              onClick={() =>
                setVisible({
                  is: true,
                  title: 'Справка-расчет',
                  uid: uid,
                  type: 'ОтчетСправка',
                })
              }
            >
              Справка-расчет
            </Button>
            <Dropdown
              overlay={() => (
                <Menu>
                  <Menu.Item
                    key='1'
                    onClick={() =>
                      setVisible({
                        is: false,
                        title: 'Справка-расчет',
                        uid: uid,
                        type: 'ОтчетСправка',
                      })
                    }
                  >
                    Скачать PDF
                  </Menu.Item>
                </Menu>
              )}
              trigger={['click']}
            >
              <Button type='primary' icon={<DownloadOutlined />} />
            </Dropdown>
          </div>
        </Row>
      )}

      {settings.ОтображатьОплатить && (
        <Row className='card-row'>
          <div className='card-row-btns'>
            <Button type='primary' className='card-row-btn' onClick={() => openPayment('sber')}>
              Оплатить Сбер
            </Button>
          </div>
        </Row>
      )}

      {settings.ОтображатьОплатитьPayler && (
        <Row className='card-row'>
          <div className='card-row-btns'>
            <Button type='primary' className='card-row-btn' onClick={() => openPayment('payler')}>
              Оплатить Payler
            </Button>
          </div>
        </Row>
      )}

      {settings.ОтображатьОплатитьАльфабанк && (
        <Row className='card-row'>
          <div className='card-row-btns'>
            <Button type='primary' className='card-row-btn' onClick={() => openPayment('alfa')}>
              Оплатить Альфа Банк
            </Button>
          </div>
        </Row>
      )}

      {settings.ОтображатьОплатитьБанкОткрытие && (
        <Row className='card-row'>
          <div className='card-row-btns'>
            <Button type='primary' className='card-row-btn' onClick={() => openPayment('opening')}>
              Оплатить Банк Открытие
            </Button>
          </div>
        </Row>
      )}

      {settings.ОтображатьОплатитьQRКодом &&
        !settings.ОтображатьОплатитьQRКодомСБП &&
        !settings.ОтображатьОплатитьКомиссиюQRКодомСБП && (
          <Row className='card-row'>
            <div className='card-row-btns'>
              <Button type='primary' className='card-row-btn' onClick={() => openQR('simple')}>
                Оплатить QR-кодом
              </Button>
            </div>
          </Row>
        )}

      {settings.ОтображатьОплатитьQRКодомСбер &&
        !settings.ОтображатьОплатитьQRКодомСБП &&
        !settings.ОтображатьОплатитьКомиссиюQRКодомСБП && (
          <Row className='card-row'>
            <div className='card-row-btns'>
              <Button type='primary' className='card-row-btn' onClick={() => openQR('sber')}>
                Оплатить QR-кодом Сбер
              </Button>
            </div>
          </Row>
        )}

      {settings.ОтображатьОплатитьQRКодомСБП && (
        <Row className='card-row'>
          <div className='card-row-btns'>
            <Button
              type='primary'
              className='card-row-btn'
              onClick={() => (
                openQR('sbp'),
                settings.ОтображатьОплатитьКомиссиюQRКодомСБП &&
                  setType('ПлатежПоДоговоруЗаймаКомиссияПени')
              )}
            >
              {settings.ОтображатьОплатитьКомиссиюQRКодомСБП ? 'Оплатить СБП ЧВ' : 'Оплатить СБП'}
            </Button>
          </div>
        </Row>
      )}
      {settings.ОтображатьОплатитьКомиссиюQRКодомСБП && (
        <Row className='card-row'>
          <div className='card-row-btns'>
            <Button
              type='primary'
              className='card-row-btn'
              onClick={() => (openQR('sbp'), setType('ПлатежПоДоговоруЗаймаБезКомиссии'))}
            >
              Оплатить СБП без ЧВ
            </Button>
          </div>
        </Row>
      )}

      {settings.ОтображатьОплатитьСбербанкОнлайн && (
        <Row className='card-row'>
          <div className='card-row-btns'>
            <Button type='primary' className='card-row-btn card-row-btn__link'>
              <a href='https://online.sberbank.ru' target='_blank' rel='noopener noreferrer'>
                Оплатить через Сбербанк Онлайн
              </a>
            </Button>
          </div>
        </Row>
      )}
      {settings.ОтображатьЗаявлениеНаДосрочноеГашение && (
        <Row className='card-row'>
          <div className='card-row-btns'>
            <Dropdown
              overlay={() => (
                <Menu>
                  <Menu.Item key='1' onClick={openEarly}>
                    Заявление на досрочное погашение
                  </Menu.Item>
                </Menu>
              )}
              trigger={['click']}
            >
              <Button type='primary' className='card-row-btn card-row-btn__link'>
                Дополнительные возможности <CaretDownOutlined />
              </Button>
            </Dropdown>
          </div>
        </Row>
      )}
    </>
  );
};

export default memo(BtnsBlock);
