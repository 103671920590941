const getPasswordRules = (params) => {
  let arr = params.map(el => {
    let a = el.split('=');
    let obj = {
      key: a[0],
      length: +a[1],
    };
    return obj
  })
  // перед возвратом убираем все значения нулевые, длина пароля не может быть 0, остальные параметры могут.
  return arr.filter(el => el.length);
};

export default getPasswordRules;