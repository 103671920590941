// Action types
const CHANGE_GLOBAL_ERROR = 'GLOBAL ERROR/ Change global error';

// Reducer
const globalError = (state = null, action) => {
  switch (action.type) {
    case CHANGE_GLOBAL_ERROR:
      return action.payload
    default:
      return state;
  }
};

// Actions
export const changeGlobalError = err => {
  return {
    type: CHANGE_GLOBAL_ERROR,
    payload: err,
  }
};

export default globalError;