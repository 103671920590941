import getDefaultObject from '../../heplers/getDefaultObject';
import axios from 'axios';
import getDefaultHeader from '../../heplers/getDefaultHeader';
import { changeGlobalError } from './globalError';
import downloadFileFromUrl from '../../heplers/downloadFileFromUrl';
import { setFileIsLoading } from './messages';

const BASE_API_PATH = import.meta.env.VITE_REACT_APP_API_URL;
const ADD_MESSAGES_SERVER_RESPONSE = 'MESSAGES/ Add server response';
const SET_FILE_IS_LOADING = 'FILE/ Set is loading file flag';

export const loadVoteFile = (uid, owner) => (dispatch) => {
  let obj = {
    ...getDefaultObject(),
    UIDФайла: uid,
    Владелец: owner,
  };

  dispatch(setFileIsLoading(true));

  return axios
    .post(`${BASE_API_PATH}/messages/get_file_content`, obj, getDefaultHeader())
    .then((res) => {
      dispatch(setFileIsLoading(false));
      if (res.data.status && res.data.status === 'error') {
        dispatch(changeGlobalError('1C_error'));
      } else {
        if (res.data.error_key === '0') {
          if (res.data.content) {
            downloadFileFromUrl(res.data.content, `${res.data.filename}.${res.data.filetype}`);
          }
        } else {
          if (res.data.error_key !== '-1' && res.data.error.key !== '-2') {
            dispatch({
              type: ADD_MESSAGES_SERVER_RESPONSE,
              payload: res.data,
            });
          } else {
            dispatch(changeGlobalError('session_fail'));
          }
        }
      }
    })
    .catch((err) => {
      dispatch({
        type: SET_FILE_IS_LOADING,
        payload: false,
      });
      if (err.response) {
        console.log(err.response);
        dispatch(changeGlobalError('server_error'));
      } else {
        dispatch(changeGlobalError('no_internet'));
      }
    });
};

export const sendVote = (answers, sms) => (dispatch, getState) => {
  const state = getState();
  const resultAnswers = Object.entries(answers)
    .map(([key, value]) =>
      ({ "UIDВопроса": key, "Результат": value }))

  const uuidprotocol = state.user.data["ИнформацияОГолосовании"]["UIDПротокола"]

  console.log("uuidprotocol ->", uuidprotocol);
  const { ИННОрганизации, ВерсияПриложения, UIDКлиента, UIDСессии } = getDefaultObject()
  let obj = {
    ИННОрганизации,
    ВерсияПриложения,
    UIDКлиента,
    UIDСессии,
    UIDПротокола: uuidprotocol,
    РезультатыГолосования: resultAnswers,
    КодСМС: sms,
    ДанныеПодписи: navigator.userAgent,

  }

  dispatch(setFileIsLoading(true))

  return axios
    .post(`${BASE_API_PATH}/user/vote`, obj, getDefaultHeader())
    .then((res) => {
      dispatch(setFileIsLoading(false))
    })
    .then(() => {
      window.location.href = "/"
    })
    .catch((err) => {
      if (err.response) {
        console.log(err.response);
        dispatch(changeGlobalError('server_error'));
      } else {
        dispatch(changeGlobalError('no_internet'));
      }
    })
}