import { useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';

const useHistoryNavigation = () => {
  const history = useHistory();

  const goToMain = useCallback(() => {
    history.push('/');
  }, [history]);

  const goToLogin = useCallback(() => {
    history.push('/login');
  }, [history]);

  const goToEsiaRegister = useCallback(() => {
    history.push('/esia_register');
  }, [history]);

  const clearUrl = useCallback(() => {
    history.replace('/');
  }, [history]);

  return { goToMain, goToLogin, goToEsiaRegister, history, clearUrl, Link };
};

export default useHistoryNavigation;