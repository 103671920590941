import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loadFile, acceptMessage } from '../../../../../../redux/ducks/messages';
import { Card, Button } from 'antd';
import AcceptModal from '../../../Modals/AcceptModal/AcceptModal';
import { PaperClipOutlined } from '@ant-design/icons';
import reload from '../../../../../../assets/reload.svg';
import { makeDataWithTime } from '../../../../../../heplers/dataCoverter';
import parseBr from '../../../../../../heplers/parseBr';
import ReactHtmlParser from 'react-html-parser';

const MessageItem = React.forwardRef(({ item, setInitMsg }, ref) => {

  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState({ is: false, flags: {}, UIDСообщения: item.UIDСообщения });

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      !item.Подписан &&
      (item.ПодписьЧерезСМС || item.ПодписьЧерезСелфиКод || item.ПодписьЧерезСелфиПаспорт)
    ) {
      setModal((modal) => {
        return {
          ...modal,
          flags: {
            ПодписьЧерезСМС: item.ПодписьЧерезСМС,
            ПодписьЧерезСелфиКод: item.ПодписьЧерезСелфиКод,
            ПодписьЧерезСелфиПаспорт: item.ПодписьЧерезСелфиПаспорт,
          },
        };
      });
    }
  }, [item]);

  const startAcception = () => {
    const vals = {
      UIDСообщения: item.UIDСообщения,
      Прочитано: true,
    };
    setIsLoading(true);
    dispatch(acceptMessage(vals));
  };

  const getBtnsBlock = (item) => {
    if (
      item.Статус === 'Отправлено' ||
      item.ПодписьЧерезСМС ||
      item.ПодписьЧерезСелфиКод ||
      item.ПодписьЧерезСелфиПаспорт
    ) {
      return (
        <div className='card-btns-holders'>
          {(item.ПодписьЧерезСМС || item.ПодписьЧерезСелфиКод || item.ПодписьЧерезСелфиПаспорт) &&
            !item.Подписан && (
              <div>
                <Button type='primary' onClick={() => setModal({ ...modal, is: true })}>
                  Подписать
                </Button>
              </div>
            )}

          {item.Статус === 'Отправлено' && (
            <div>
              <Button className='btn-accept' loading={isLoading} onClick={() => startAcception()}>
                Подтвердить получение
              </Button>
            </div>
          )}
        </div>
      );
    } else {
      return <></>;
    }
  };

  const getFiles = (files, flag) => {
    let arr = [];
    if (Array.isArray(files)) {
      arr = [...files];
    } else {
      let a = [];
      a.push(files);
      arr = [...arr, ...a];
    }

    return arr.map((el) => (
      <p key={el.UIDФайла} onClick={() => dispatch(loadFile(el.UIDФайла, flag))}>
        <PaperClipOutlined /> {el.Наименование}.{el.РасширениеФайла}
      </p>
      // <Button type='link' key={el.UIDФайла} onClick={() => dispatch(loadFile(el.UIDФайла, flag))}>
      //   <PaperClipOutlined /> {el.Наименование}.{el.РасширениеФайла}
      // </Button>
    ));
  };

  return (
    <>
      <div
        ref={ref}
        className={
          item.Автор
            ? item.Статус === 'Отправлено'
              ? 'card-item-holder card-item-holder__accept'
              : 'card-item-holder'
            : 'align-right card-item-holder'
        }
      >
        <Card className='card'>
          <p className='card-title'>
            <span>
              <strong>{item.Автор ? item.Автор : 'Вы'} </strong>
              <span className='text-span'>
                № {item.Номер} от {makeDataWithTime(item.Дата)}
              </span>
            </span>
            <span>
              <img
                src={reload}
                alt='icon'
                onClick={() => setInitMsg({ ...item, init: item.Автор ? 1 : 0 })}
              />
            </span>
          </p>
          <p>
            <span className='text-span'>Статус:</span> <span>{item.Статус}</span>
          </p>

          {item.Основание && (
            <div className='reply-msg'>
              <p>Ответ на сообщение {item.Основание}</p>
            </div>
          )}

          <p>
            <span className='text-muted'>Тема: </span>
            <span>{ReactHtmlParser(item.Тема)} </span>
          </p>
          <p className='text-msg'>{ReactHtmlParser(parseBr(item.ТекстСообщения))}</p>

          {item.ВложенныеФайлы && (
            <div className='links-holder'>
              {getFiles(item.ВложенныеФайлы.Файл, item.Автор ? false : true)}
            </div>
          )}

          {getBtnsBlock(item)}
        </Card>
      </div>
      <AcceptModal
        visible={modal.is}
        onCancel={() => {
          setModal({ ...modal, is: false });
        }}
        flags={modal.flags}
        UIDСообщения={item.UIDСообщения}
      />
    </>
  );
});

export default memo(MessageItem);
