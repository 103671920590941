import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, notification } from 'antd';
import PhoneForm from './components/PhoneForm';
import AcceptPhoneForm from './components/AcceptPhoneSMSForm';
import {
  createNewESIAClient,
  clearUserServerResponse,
  getLoans,
  getRequisites,
  createSpecificMessage,
} from '../../../../redux/ducks/user';
import addUserToLocal from '../../../../heplers/addUserToLocal';

const initialPhone = { number: null, isReady: false };

const Block1 = ({ active, loading }) => {
  const { activeBlock, setActiveBlock } = active;
  const { isLoading, setIsLoading } = loading;

  const [disabled, setDisabled] = useState({ is: true });
  const [smsWork, setSmsWork] = useState(false);

  const [phone, setPhone] = useState(initialPhone);

  const serverResponse = useSelector((state) => state.user.serverResponse);

  const dispatch = useDispatch();

  const registerEsiaUser = () => {
    setPhone({ ...phone, isReady: false });
    setIsLoading(true);
    dispatch(createNewESIAClient({ phone: phone.number }));
  };

  useEffect(() => {
    if (isLoading && serverResponse.status === 'error') {
      setIsLoading(false);
      notification.error({
        message: 'Ошибка',
        description: 'Регистрация не удалась',
      });
    }
    if (isLoading && serverResponse.status === 200) {
      setIsLoading(false);
      if (serverResponse.data.return && serverResponse.data.return.КодОшибки === '0') {
        Promise.resolve()
          .then(() => {
            addUserToLocal({ ...serverResponse.data.return, phone: serverResponse.phone });
          })
          .then(() => {
            dispatch(getRequisites());
            dispatch(getLoans());
            dispatch(createSpecificMessage());
          })
          .then(() => {
            setActiveBlock(2);
          });
      } else {
        notification.error({
          message: 'Ошибка',
          description: serverResponse.data.return.ОписаниеОшибки
            ? serverResponse.data.return.ОписаниеОшибки
            : 'Регистрация не удалась',
        });
        // changeError(serverResponse.data.return.ОписаниеОшибки);
      }
      dispatch(clearUserServerResponse());
    }
  }, [serverResponse, dispatch, isLoading, setActiveBlock, setIsLoading]);

  return (
    <Card
      title='Укажите номер мобильного телефона'
      className={activeBlock === 1 ? 'esia-block' : 'esia-block unactive-block'}
    >
      <PhoneForm
        phone={phone}
        onFinishPhone={(values) => {
          setPhone({
            number: values.phone,
            isReady: true,
          });
        }}
        phoneIsntReady={() => {
          setPhone(initialPhone);
        }}
      />
      <div className={phone.isReady ? 'loan-card-holder visible' : ' loan-card-holder invisible'}>
        <AcceptPhoneForm
          visible={phone.isReady}
          setDisabled={setDisabled}
          disabled={disabled}
          setSmsWork={setSmsWork}
          smsWork={smsWork}
          phone={phone}
          registerEsiaUser={registerEsiaUser}
        />
      </div>
    </Card>
  );
};

export default Block1;
