import { useState, useEffect, useCallback } from 'react';

const useWindowSize = () => {
    const [width, changeWidth] = useState(window.innerWidth);
    const [height, changeHeight] = useState(window.innerHeight);

    const widthChange = useCallback(() => {
        changeWidth(window.innerWidth);
        changeHeight(window.innerHeight);
    }, []);
    useEffect(() => {
        window.addEventListener('resize', widthChange);
        return () => {
            window.removeEventListener('resize', widthChange);
        };
    }, [widthChange]);

    return { width, height };
};

export default useWindowSize;