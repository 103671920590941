import { Col, Row } from 'antd'
import React, { memo } from 'react'
// import BtnsBlock from './BtnsBlock';
import { useSelector } from 'react-redux'
import BtnsBlock from './BtnsBlock'
import DataBlock from './DataBlock'

const ContributionCard = ({ record, colNum }) => {
	const config = useSelector(state => state.config)
	const settings = config['1c_configs'].return.НастройкиВзносов
	const settingsNew = config['1c_configs'].return.НастройкиПериодическихВзносов

	return (
		<div className='more-info-wrapper'>
			{colNum === 24 ? (
				<>
					<Row>
						<Col span={colNum}>
							<DataBlock record={record} />
						</Col>
					</Row>
				</>
			) : (
				// desctop
				<>
					<Row>
						<Col span={colNum}>
							<DataBlock record={record} />
						</Col>

						<Col span={colNum}>
							<BtnsBlock
								key={record.Взнос.UIDВзноса}
								settings={settings}
								record={record}
								settingsNew={settingsNew}
							/>
						</Col>
					</Row>
				</>
			)}
		</div>
	)
}

export default memo(ContributionCard)
