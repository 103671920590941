import React, { memo } from 'react';
import { Row, Button, Dropdown, Menu } from 'antd';
import { DownloadOutlined, CaretDownOutlined } from '@ant-design/icons';

const BtnsBlock = ({ setVisible, uid, openPayment, settings, openEarly, openQR }) => {
  return (
    <>
      {settings.ОтображатьКарточку && (
        <Row className='card-row'>
          <div className='card-row-btns'>
            <Button
              type='primary'
              className='card-row-btn'
              onClick={() =>
                setVisible({
                  is: true,
                  title: 'Карточка сбережения',
                  uid: uid,
                  type: 'ОтчетКарточка',
                })
              }
            >
              Карточка сбережения
            </Button>
            <Dropdown
              overlay={() => (
                <Menu>
                  <Menu.Item
                    key='1'
                    onClick={() =>
                      setVisible({
                        is: false,
                        title: 'Карточка сбережения',
                        uid: uid,
                        type: 'ОтчетКарточка',
                      })
                    }
                  >
                    Скачать PDF
                  </Menu.Item>
                </Menu>
              )}
              trigger={['click']}
            >
              <Button type='primary' icon={<DownloadOutlined />} />
            </Dropdown>
          </div>
        </Row>
      )}
      {settings.ОтображатьГрафик && (
        <Row className='card-row'>
          <div className='card-row-btns'>
            <Button
              type='primary'
              className='card-row-btn'
              onClick={() =>
                setVisible({
                  is: true,
                  title: 'График сбережения',
                  uid: uid,
                  type: 'ОтчетГрафик',
                })
              }
            >
              График сбережения
            </Button>
            <Dropdown
              overlay={() => (
                <Menu>
                  <Menu.Item
                    key='1'
                    onClick={() =>
                      setVisible({
                        is: false,
                        title: 'График сбережения',
                        uid: uid,
                        type: 'ОтчетГрафик',
                      })
                    }
                  >
                    Скачать PDF
                  </Menu.Item>
                </Menu>
              )}
              trigger={['click']}
            >
              <Button type='primary' icon={<DownloadOutlined />} />
            </Dropdown>
          </div>
        </Row>
      )}
      {settings.ОтображатьСправку && (
        <Row className='card-row'>
          <div className='card-row-btns'>
            <Button
              type='primary'
              className='card-row-btn'
              onClick={() =>
                setVisible({
                  is: true,
                  title: 'Справка-расчет',
                  uid: uid,
                  type: 'ОтчетСправка',
                })
              }
            >
              Справка-расчет
            </Button>
            <Dropdown
              overlay={() => (
                <Menu>
                  <Menu.Item
                    key='1'
                    onClick={() =>
                      setVisible({
                        is: false,
                        title: 'Справка-расчет',
                        uid: uid,
                        type: 'ОтчетСправка',
                      })
                    }
                  >
                    Скачать PDF
                  </Menu.Item>
                </Menu>
              )}
              trigger={['click']}
            >
              <Button type='primary' icon={<DownloadOutlined />} />
            </Dropdown>
          </div>
        </Row>
      )}
      {settings.ОтображатьПополнить && (
        <Row className='card-row'>
          <div className='card-row-btns'>
            <Button type='primary' className='card-row-btn' onClick={() => openPayment('sber')}>
              Пополнить
            </Button>
          </div>
        </Row>
      )}

      {settings.ОтображатьПополнитьQRКодом && (
        <Row className='card-row'>
          <div className='card-row-btns'>
            <Button type='primary' className='card-row-btn' onClick={() => openQR('simple')}>
              Пополнить QR-кодом
            </Button>
          </div>
        </Row>
      )}

      {settings.ОтображатьПополнитьQRКодомСбер && !settings.ОтображатьПополнитьQRКодомСБП && (
        <Row className='card-row'>
          <div className='card-row-btns'>
            <Button type='primary' className='card-row-btn' onClick={() => openQR('sber')}>
              Пополнить QR-кодом Сбер
            </Button>
          </div>
        </Row>
      )}
      {settings.ОтображатьПополнитьQRКодомСБП && (
        <Row className='card-row'>
          <div className='card-row-btns'>
            <Button type='primary' className='card-row-btn' onClick={() => openQR('sbp')}>
              Пополнить QR-кодом СБП
            </Button>
          </div>
        </Row>
      )}

      {settings.ОтображатьПополнитьСбербанкОнлайн && (
        <Row className='card-row'>
          <div className='card-row-btns'>
            <Button type='primary' className='card-row-btn' style={{ height: 'auto' }}>
              <a
                href='https://online.sberbank.ru'
                target='_blank'
                rel='noopener noreferrer'
                style={{ whiteSpace: 'initial' }}
              >
                Пополнить через Сбербанк Онлайн
              </a>
            </Button>
          </div>
        </Row>
      )}

      {settings.ОтображатьЗаявлениеНаДосрочноеСнятие && (
        <Row className='card-row'>
          <div className='card-row-btns'>
            <Dropdown
              overlay={() => (
                <Menu>
                  <Menu.Item key='1' onClick={openEarly}>
                    Заявление на досрочное снятие сбережений
                  </Menu.Item>
                </Menu>
              )}
              trigger={['click']}
            >
              <Button type='primary' className='card-row-btn card-row-btn__link'>
                Дополнительные возможности <CaretDownOutlined />
              </Button>
            </Dropdown>
          </div>
        </Row>
      )}
    </>
  );
};

export default memo(BtnsBlock);
