import React, { memo } from 'react';
import { Menu, Dropdown } from 'antd';
import { DownOutlined, UserOutlined, MenuOutlined } from '@ant-design/icons';

import useWindowSize from '../../../../../hooks/useWindowSize';
import removeUserFromLocal from '../../../../../heplers/removeUserFromLocal';
import useHistoryNavigation from '../../../../../hooks/useHistoryNavigation';

const UserDropdown = ({ openChangeModal }) => {
  const { goToLogin, Link } = useHistoryNavigation();
  const { width } = useWindowSize();

  const menu = (
    <Menu>
      {width <= 450 && (
        <p className='user-title'>
          <UserOutlined /> {JSON.parse(localStorage.getItem('user'))}
        </p>
      )}
      {width <= 450 && <Menu.Divider />}
      <Menu.Item key='2' onClick={openChangeModal}>
        Изменить пароль
      </Menu.Item>
      <Menu.Divider />

      <Menu.Item
        key='3'
        onClick={() => {
          removeUserFromLocal();
          goToLogin();
        }}
      >
        Выйти
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Link className='ant-dropdown-link' to='/' onClick={(e) => e.preventDefault()}>
        {width > 450 ? JSON.parse(localStorage.getItem('user')) : <MenuOutlined />} <DownOutlined />
      </Link>
    </Dropdown>
  );
};

export default memo(UserDropdown);
