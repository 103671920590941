import React, { memo } from 'react';
import { Form, Input } from 'antd';
import { LockOutlined } from '@ant-design/icons';

const RePassword = ({ isLoading }) => {
  return (
    <Form.Item
      name='repeat_password'
      label='Подтверждение пароля'
      dependencies={['password']}
      hasFeedback
      rules={[
        {
          required: true,
          message: 'Подтвердите пароль!',
        },
        ({ getFieldValue }) => ({
          validator(rule, value) {
            if (!value || getFieldValue('password') === value) {
              return Promise.resolve();
            }
            return Promise.reject('Ввведенные пароли не совпадают');
          },
        }),
      ]}
    >
      <Input.Password
        placeholder=''
        prefix={<LockOutlined className='site-form-item-icon' />}
        disabled={isLoading}
        allowClear
      />
    </Form.Item>
  );
};

export default memo(RePassword);