import React, { memo, useEffect } from 'react';
import { Form, InputNumber, Input } from 'antd';

const { TextArea } = Input;

const PaymentForm = ({ form, isLoading, initSum, visible }) => {
  useEffect(() => {
    if (visible === 'opening') {
      form.setFieldsValue({
        Комментарий:
          'При оплате через личный кабинет КПК «КРЕДО» датой исполнения обязательств считается дата поступления денежных средств на расчётный счёт Кооператива. Зачисление средств производится банком в течение двух рабочих дней.',
      });
    };
    if (visible === 'payler') {
      form.setFieldsValue({
        Сумма: initSum.toFixed(2),
      });
    };
  }, [form, visible]);

  return (
    <Form
      form={form}
      layout='vertical'
      name='payment_modal'
      size={'middle'}
      initialValues={{
        Сумма: initSum ? initSum : 0,
      }}
    >
      <Form.Item
        name='Сумма'
        label='Сумма платежа (руб.)'
        rules={[
          {
            required: true,
            message: 'Введите сумму',
          },
        ]}
      >
        <InputNumber
          suffix='руб.'
          formatter={(value) => `${value}`.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1 ')}
          parser={(value) => value.replace(/(\s*)руб.?|(\s*)/g, '')}
          readOnly={isLoading}
          min={0.01}
          max={999999.99}
          autoFocus
        />
      </Form.Item>

      <div className='loan-comment'>
        <p>Комментарий</p>
        <Form.Item name='Комментарий' className='loan-input'>
          <TextArea
            rows={4}
            autoComplete='off'
            autoCorrect='off'
            spellCheck='false'
            readOnly={visible === 'opening' ? true : isLoading}
          />
        </Form.Item>
      </div>
    </Form>
  );
};

export default memo(PaymentForm);
