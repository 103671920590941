import React, { useState, memo } from 'react';
import { Form, Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const AcceptForm = ({ form, isLoading, flags, visible }) => {
  const [fileList, setFileList] = useState([]);

  const props = {
    multiple: false,
    showUploadList: {
      showDownloadIcon: false,
    },
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      // let a = [...fileList, file];
      let a = [file];
      setFileList(a);
      return false;
    },
    fileList,
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    };
    if (e.fileList.length > 1) {
      e.fileList.shift();
    };
    return e && e.fileList;
  };

  return (
    <>
      <Form form={form} layout='vertical' name='msg_modal' size={'middle'}>
        {(flags.ПодписьЧерезСелфиКод || flags.ПодписьЧерезСелфиПаспорт) && (
          <Form.Item
            name='upload'
            label={
              flags.ПодписьЧерезСелфиКод
                ? 'Прикрепите Селфи + CМС-Код'
                : flags.ПодписьЧерезСелфиПаспорт
                ? 'Прикрепите Селфи + Паспорт'
                : 'Прикрепите селфи'
            }
            valuePropName='fileList'
            getValueFromEvent={normFile}
            rules={[
              {
                required: true,
                message: 'Прикрепите селфи',
              },
            ]}
          >
            <Upload name='logo' listType='text' multiple={false} {...props} accept='image/*'>
              <Button disabled={isLoading}>
                <UploadOutlined /> Прикрепить селфи
              </Button>
            </Upload>
          </Form.Item>
        )}
      </Form>
    </>
  );
};

export default memo(AcceptForm);
