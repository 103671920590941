import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import MainPage from '../pages/MainPage/MainPage';
import LoginPage from '../pages/LoginPage/LoginPage';
import ErrorPage from '../pages/ErrorPage/ErrorPage';
import ESIARegisterPage from '../pages/ESIARegisterPage/ESIARegisterPage';
import Loader from '../common/components/Loader/Loader';

const PrivateRoute = ({ component, ...options }) => {
  const authorised = JSON.parse(localStorage.getItem('user'));
  if (authorised) {
    return <Route {...options} component={component} />;
  } else {
    return <Redirect to='/login' />;
  }
};

const UnPrivateRoute = ({ component, isLoading, ...options }) => {
  const authorised = JSON.parse(localStorage.getItem('user'));
  if (authorised) {
    return <Redirect to='/' />;
  } else {
    if(isLoading){
      return (
        <Route {...options}>
          <Loader isLoading/>
        </Route>
      )
    } else {
      return <Route {...options} component={component} />;
    };
  };
};

const Router = () => {
  return (
    <Switch>
      <PrivateRoute exact path='/' component={MainPage} />
      <PrivateRoute exact path='/payment/success' component={MainPage} />
      <PrivateRoute exact path='/payment/fail' component={MainPage} />
      <UnPrivateRoute exact path='/login' component={LoginPage} />
      <UnPrivateRoute exact path='/esia_result' component={LoginPage} isLoading/>
      <UnPrivateRoute exact path='/esia_register' component={ESIARegisterPage} />
      <Route path='*' component={ErrorPage} />
    </Switch>
  );
};

export default Router;
