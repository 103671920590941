import React, { memo, useEffect, useCallback, useState } from 'react';
import { Modal, Form } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  createPayment,
  clearPaymentServerResponse,
  createPaymentPayler,
  createOpeningPayment,
  createPaymentAlfa,
} from '../../../../../redux/ducks/payment';

import PaymentForm from './PaymentForm/PaymentForm';
import moment from 'moment';

const PaymentModal = ({
  visible,
  onCancel,
  uid,
  docNum,
  type,
  initSum,
  title,
  btnText,
  tab,
  loanNum,
  тПлатеж,
  ДатаДоговора,
}) => {
  const [isLoading, changeIsLoading] = useState(false);

  const [form] = Form.useForm();
  const serverResponse = useSelector((state) => state.payment.serverResponse);
  const userData = useSelector((state) => state.user.data);

  const dispatch = useDispatch();

  const onCreate = (values) => {
    let vals = {};
    // console.log(values)
    if (visible === 'sber' || visible === 'payler' || visible === 'alfa') {
      vals = {
        ...values,
        Комментарий: values.Комментарий ? values.Комментарий : ' ',
        ВидОперации: type,
        UIDОснование: uid,
        description: `ФИО: ${JSON.parse(localStorage.getItem('user'))}, № договора: ${
          docNum || ' '
        }, дата: ${moment().format('DD.MM.yyyy')}`,
      };
    }
    if (visible === 'opening') {
      // console.log(userData.ИнформацияОКлиенте, type);
      let a = userData.ИнформацияОКлиенте;
      vals = {
        UIDКлиента: a.UIDКлиента,
        UIDФизЛица: a.ФизЛицо.UIDФизЛица,
        UIDПодразделения: a.Подразделение.UIDПодразделения,
        UIDДоговора: uid,
        UIDЗайма: loanNum,
        НомерДоговора: docNum,
        Описание: values.Комментарий ? values.Комментарий : ' ',
        Сумма: values.Сумма,
        description: getDescription(values.Сумма),
      };
    }
    // console.log(vals);
    changeIsLoading(true);
    if (visible === 'sber') {
      dispatch(createPayment({ vals, tab }));
    }
    if (visible === 'payler') {
      dispatch(createPaymentPayler({ vals, tab }));
    }
    if (visible === 'opening') {
      dispatch(createOpeningPayment({ vals, tab }));
    }
    if (visible === 'alfa') {
      dispatch(createPaymentAlfa({ vals, tab }));
    }
  };

  const getDescription = (sum) => {
    let a = '';
    // console.log(тПлатеж);
    if (+тПлатеж.Комиссия + +тПлатеж.ПениКомиссия === 0) {
      a =
        'Платеж по договору № ' +
        docNum +
        ' от ' +
        moment(ДатаДоговора).format('DD.MM.yyyy') +
        'г.';
    } else if (sum <= +тПлатеж.Комиссия + +тПлатеж.ПениКомиссия) {
      a = 'Платеж по соглашению от ' + moment(ДатаДоговора).format('DD.MM.yyyy') + 'г.';
    } else {
      a =
        'Платеж по соглашению от ' +
        moment(ДатаДоговора).format('DD.MM.yyyy') +
        'г. и по договору № ' +
        docNum +
        ' от ' +
        moment(ДатаДоговора).format('DD.MM.yyyy') +
        'г.';
    }
    return a;
  };

  const clearModal = useCallback(() => {
    form.resetFields();
    changeIsLoading(false);
  }, [form]);

  const closeConfirm = () => {
    let values = form.getFieldsValue();

    if (JSON.stringify(values) === '{}') {
      onCancel();
    } else {
      Modal.confirm({
        // title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Вы действительно хотите прервать процесс оплаты? Введенные данные не сохранятся.',
        okText: 'Да',
        cancelText: 'Нет',
        centered: true,
        onOk: () => {
          onCancel();
          setTimeout(() => {
            clearModal();
          }, 200);
        },
      });
    }
  };

  const msgErrorConfirm = useCallback((err) => {
    Modal.error({
      title: 'Ошибка!',
      content: err,
      centered: true,
      onOk: () => {},
    });
  }, []);

  const msgSuccessConfirm = useCallback(() => {
    Modal.success({
      title: 'Готово!',
      content: 'Сообщение отправлено',
      centered: true,
      onOk: () => {
        onCancel();
        setTimeout(() => {
          clearModal();
        }, 200);
      },
    });
  }, [onCancel, clearModal]);

  useEffect(() => {
    if (
      serverResponse &&
      (serverResponse.status === 400 ||
        serverResponse.status === 422 ||
        serverResponse.status === 500) &&
      isLoading
    ) {
      msgErrorConfirm(serverResponse.data);
      changeIsLoading(false);
      dispatch(clearPaymentServerResponse());
    }
    // console.log(serverResponse)
    if (
      serverResponse &&
      (serverResponse.code === 400 || serverResponse.code === 422 || serverResponse.code === 500) &&
      isLoading
    ) {
      msgErrorConfirm(serverResponse.error_message);
      changeIsLoading(false);
      dispatch(clearPaymentServerResponse());
    }
  }, [serverResponse, msgSuccessConfirm, msgErrorConfirm, isLoading, dispatch]);

  return (
    <Modal
      open={visible}
      title={title}
      okText={btnText}
      cancelText='Выйти'
      onCancel={closeConfirm}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
      className='change-password-modal'
      centered
      confirmLoading={isLoading}
    >
      <PaymentForm
        form={form}
        isLoading={isLoading}
        initSum={initSum}
        visible={visible}
      />
    </Modal>
  );
};

export default memo(PaymentModal);
